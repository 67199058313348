<template>
  <div>
    <ui-header :heading="$tc('models.company', 2)">
      <auth-checker :accessRoles="['SuperAdmin']">
        <ui-link class="create_new_button" type="text" :route="{ name: 'companies-create' }">{{
          `${$tc('actions.add_new', 1)} ${$tc('models.company')}`
        }}</ui-link>
      </auth-checker>
    </ui-header>

    <company-list :companies="companies" v-loading="loading" />
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import CompanyList from '../components/CompanyList';
import AuthChecker from '../../auth/components/AuthChecker';
import { getCompanies } from '../api';

export default {
  data() {
    return {
      companies: [],
      loading: false
    };
  },

  components: {
    UiHeader,
    UiLink,
    CompanyList,
    AuthChecker
  },

  methods: {
    async getCompanies() {
      this.loading = true;
      try {
        const companies = await getCompanies();
        this.companies = companies.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', {
            msg: this.$tc('models.company', 2)
          }),
          type: 'error'
        };
        this.$message(msg);
      }
    }
  },

  created() {
    this.getCompanies();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.companies", link: "/app/companies/"}
    ]);
  },
};
</script>

<style scoped>

.create_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}

</style>